import React from "react";

export default function MasterHead() {
  return (
    <header className="masthead">
      <div className="container">
        <div className="masthead-subheading ">
          If you are interested in become a software developer, <br />
          you are at the right place
        </div>
        <a className="btn btn-primary btn-xl" href="#services">
          Learn more
        </a>
      </div>
    </header>
  );
}
