import React from "react";

export default function Footer() {
  return (
    <footer className="footer py-4 bg-dark text-white">
      <div className="container">
        <div className="row align-items-center">
          {/* Copyright */}
          <div className="col-lg-12 text-lg-center">
            Copyright © 2021 Software Development Academy
          </div>
        </div>
      </div>
    </footer>
  );
}
