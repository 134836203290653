import React from "react";

export default function Modules() {
  const data = [
    {
      image: "modules/programing-foundations.png",
      link: "#portfolioModal1",
      title: "Programing Foundations",
      subtitle: "To learn the basics from zero",
    },
    {
      image: "modules/software-engineering.png",
      link: "#portfolioModal1",
      title: "Software Engineering",
      subtitle: "To manage complex projects",
    },
    {
      image: "modules/individual-project.png",
      link: "#portfolioModal1",
      title: "Individual project",
      subtitle: "Create your first real application",
    },
    {
      image: "modules/programing-in-depth.png",
      link: "#portfolioModal1",
      title: "Programming in Depth",
      subtitle: "To create more complex applications",
    },
    {
      image: "modules/front-end.png",
      link: "#portfolioModal1",
      title: "Front End development",
      subtitle: "For building websites and web apps",
    },
    {
      image: "modules/back-end.png",
      link: "#portfolioModal1",
      title: "Back End development",
      subtitle: "Create databases and manage servers",
    },
    {
      image: "modules/group-project.png",
      link: "#portfolioModal1",
      title: "Group Project",
      subtitle: "Build a bigger project with your team",
    },
  ];

  const Items = data.map((item, index) => {
    const Image = require(`../assets/img/${item.image}`);

    return (
      <div className="col-lg-4 col-sm-6 mb-4" key={index}>
        <article className="portfolio-item">
          <div>
            <div className="portfolio-hover">
              {/* <div className="portfolio-hover-content">
                <i className="fas fa-plus fa-3x"></i>
              </div> */}
            </div>
            <img className="img-fluid" src={Image} alt="" />
          </div>
          <div className="portfolio-caption">
            <div className="portfolio-caption-heading">{item.title}</div>
            <div className="portfolio-caption-subheading text-muted">
              {item.subtitle}
            </div>
          </div>
        </article>
      </div>
    );
  });
  return (
    <section className="page-section bg-light" id="portfolio">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Modules</h2>
          <h3 className="section-subheading text-muted">
            What you will learn during the upcoming weeks
          </h3>
        </div>
        <div className="row">{Items}</div>
      </div>
    </section>
  );
}
