import React from "react";

export default function Teachers() {
  const data = [
    {
      name: "Ric Glassey",
      job: "Division of Theorical Computer Science at KTH",
      image: "ric.jpg",
    },
    {
      name: "Dena Hussain",
      job: "Division of Computational Science and Technology at KTH",
      image: "dena.jpg",
    },
    {
      name: "Philipp Haller",
      job: "Division of Theorical Computer Science at KTH",
      image: "philipp.jpg",
    },
  ];
  const Items = data.map((item, index) => {
    const Image = require(`../assets/img/team/${item.image}`);

    return (
      <div className="col-lg-4" key={index}>
        <div className="team-member">
          <img className="mx-auto rounded-circle" src={Image} alt="" />
          <h4>{item.name}</h4>
          <p className="text-muted">{item.job}</p>
        </div>
      </div>
    );
  });
  return (
    <section className="page-section bg-light" id="team">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Our professors</h2>
          <h3 className="section-subheading text-muted">
            Learn with professors that teach to Computer Science at the
            Bachelor's and Master's levels at KTH
          </h3>
        </div>
        <div className="row">{Items}</div>
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <p className="large text-muted">
              In addition to the KTH professors, we have Teaching Assistants
              selected from the last years of the Computer of Science and
              Masters Students from KTH. They will assist you with any doubt
              during your learning.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
