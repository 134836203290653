// Core
import React from "react";

// Media
import Logo from "../assets/img/navbar-logo.svg";
import { useState } from "react";

export default function Navigation() {
  // Properties
  const data = [
    { label: "About", link: "#services" },
    { label: "Modules", link: "#portfolio" },
    { label: "Our professors", link: "#team" },
    { label: "Create account", link: "https://app.sdaproject.se/sign-up" },
    { label: "Login", link: "https://app.sdaproject.se/login" },
  ];
  const Items = data.map((item, index) => {
    return (
      <li className="nav-item" key={index} onClick={onHideMenu}>
        <a className="nav-link js-scroll-trigger" href={`${item.link}`}>
          {item.label}
        </a>
      </li>
    );
  });

  const [toggle, setToggle] = useState(false);

  // Methods
  function onToggleMenu() {
    console.log("Toggle menu");
    setToggle(!toggle);
  }
  function onHideMenu() {
    console.log("Close menu");
    setToggle(false);
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">
          <img src={Logo} alt="A generic logo written with a cursive style" />
        </a>
        <button
          onClick={onToggleMenu}
          className="navbar-toggler navbar-toggler-right"
        >
          Menu
          <i className="fas fa-bars ml-1"></i>
        </button>
        <div
          className={`navbar-collapse ${toggle ? "" : "toggle"}`}
          id="navbarResponsive"
        >
          <ul className="navbar-nav text-uppercase ml-auto">{Items}</ul>
        </div>
      </div>
    </nav>
  );
}
