import React from "react";

export default function Clients() {
  const data = [
    {
      image: "kth.png",
      link: "https://www.kth.se/en",
    },
    {
      image: "novare.png",
      link: "https://www.novarepotential.se",
    },
  ];
  const Items = data.map((item, index) => {
    const Image = require(`../assets/img/logos/${item.image}`);
    return (
      <div className="col-md-6 col-sm-6 my-3" key={index}>
        <a href={item.link} target="_blank" rel="noopener noreferrer">
          <img className="img-thumbnail  d-block mx-auto" src={Image} alt="" />
        </a>
      </div>
    );
  });

  return (
    <section className="py-5 page-section">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">
            With the collaboration of
          </h2>
          <h3 className="section-subheading text-muted">
            We work clossely with KTH for academic content and Novare Potential
            for HHRR management to bring the best results
          </h3>
        </div>
        <div className="row">{Items}</div>
      </div>
    </section>
  );
}
