import React from "react";

export default function Calendar() {
  const data = [
    {
      time: "February 1",
      title: "Learning the basics",
      description:
        "The first 3 courses will help you learn about how to create an application from scratch and then, how to scale up even when using complex functionality.",
      image: "one.jpg",
      orientation: "",
    },
    {
      time: "February 22",
      title: "Start creating your first project",
      description:
        "It will teach you how every concept related to the Programming Foundations and Software Engineering modules comes together to deliver an app",
      image: "two.jpg",
      orientation: "timeline-inverted",
    },
    {
      time: "March 22",
      title: "Learn about web technologies",
      description:
        "The you move onto how to create basic websites, then we dive into the Java-Script language and it’s most popular tools to build complete web applications",
      image: "3.jpg",
      orientation: "",
    },
    {
      time: "April 19",
      title: "Create the final project",
      description:
        "The final module of the SDA will teach you how to build an ever bigger application with the help of your class mates",
      image: "four.jpg",
      orientation: "timeline-inverted",
    },
  ];
  const Items = data.map((item, index) => {
    const Image = require(`../assets/img/about/${item.image}`);

    return (
      <li className={item.orientation} key={index}>
        <div className="timeline-image">
          <img className="rounded-circle img-fluid" src={Image} alt="" />
        </div>
        <div className="timeline-panel">
          <div className="timeline-heading">
            <h4>{item.time}</h4>
            <h4 className="subheading">{item.title}</h4>
          </div>
          <div className="timeline-body">
            <p className="text-muted">{item.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return (
    <section className="page-section" id="about">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Schedule</h2>
          <h3 className="section-subheading text-muted">
            This is our planned calendar for Fall 2020
          </h3>
        </div>
        <ul className="timeline">
          {Items}

          {/* Ending */}
          <li>
            <div className="timeline-image">
              <h4>
                May 14
                <br />
                Graduation
                <br />
                Day
              </h4>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}
