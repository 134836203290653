// Core
import React from "react";

// Components
import About from "./sections/About";
import Calendar from "./sections/Calendar";
import Footer from "./sections/Footer";
import MasterHead from "./sections/MasterHead";
import Modules from "./sections/Modules";
import Navigation from "./sections/Navigation";
import Parthers from "./sections/Parthers";
import Teachers from "./sections/Teachers";

// Style
import "./styles/style.scss";

// Image Credits:
// <span>Photo by <a href="https://unsplash.com/@johnschno?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">John Schnobrich</a> on <a href="https://unsplash.com/s/photos/community?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>

function App() {
  return (
    <div className="App">
      <Navigation />
      <MasterHead />
      <About />
      <Modules />
      <Calendar />
      <Teachers />
      <Parthers />
      <Footer />
    </div>
  );
}

export default App;
