import React from "react";

export default function About() {
  const data = [
    {
      label: "University Professors",
      icon: "university",
      description:
        "The modules are created by professors from KTH. One of the best universities in Sweden and top 100 around the world.",
    },
    {
      label: "Community",
      icon: "user-friends",
      description:
        "We believe that helping each other improves the learning experience. We are inclusive and make sure all participants are welcomed. ",
    },
    {
      label: "Teaching Assistants",
      icon: "headset",
      description:
        "We have teaching assistants on Zoom to help you with your assignments or clarify any doubts during the program.",
    },
  ];
  const Items = data.map((item, index) => {
    return (
      <article className="col-md-4" key={index}>
        <span className="fa-stack fa-3x">
          <i className="fas fa-circle fa-stack-2x text-primary"></i>
          <i className={`fas fa-${item.icon} fa-stack-1x fa-inverse`}></i>
        </span>
        <h4 className="my-3">{item.label}</h4>
        <p className="text-muted">{item.description}</p>
      </article>
    );
  });

  return (
    <section className="page-section" id="services">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">About the SDA</h2>
          <h3 className="section-subheading text-muted">
            We use the power of active learning mixed with the community to
            achieve results
          </h3>
        </div>
        <div className="row text-center">{Items}</div>
      </div>
    </section>
  );
}
